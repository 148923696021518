/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { SHARED } from './shared.actionTypes';

const initialState = {
  countries: []
};

const sharedSlice = createSlice({
  name: SHARED,
  initialState,
  reducers: {
    SET_COUNTRIES: (state, { payload }) => {
      state.countries = payload;
    },
    SET_IS_LOADING_COUNTRIES: (state, { payload }) => {
      state.isLoadingCountries = payload;
    },
    SET_CURRENT_USER: (state, { payload }) => {
      state.currentUser = payload;
    },
    SET_LICENCES: (state, { payload }) => {
      state.licenses = payload;
    },
    SET_BADGES: (state, { payload }) => {
      state.badges = payload;
    },
    SET_PMS_INTEGRATIONS: (state, { payload }) => {
      state.pmss = payload;
    },
    SET_SUBSCRIPTION_EXPIRED_DATE: (state, { payload }) => {
      state.subscriptionExpiredDate = payload;
    }
  }
});

export const { actions, reducer } = sharedSlice;
