import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isArrayWithItems } from '../../../../../../utils/functions/validator/arrayValidator';
import validateIfExistValueInOptions from '../functions/controlErrorOfSelect';

const useSelectOptionsControlled = ({
  options,
  value,
  selectId,
  selectLocationId
}) => {
  const [haveValueNotFound, setHaveValueNotFound] = useState(false);
  useEffect(() => {
    const checkToValidateValueAndOptions = () => {
      try {
        const infoToControlError = {
          valueToValidate: value,
          options,
          selectId,
          selectLocationId
        };
        validateIfExistValueInOptions(infoToControlError);
        setHaveValueNotFound(false);
      } catch (error) {
        setHaveValueNotFound(true);
      }
    };
    checkToValidateValueAndOptions();
  }, [options, value, selectId, selectLocationId]);
  const optionsControlled = useMemo(() => {
    const haveOptions = isArrayWithItems(options);
    if (!haveOptions) {
      return [];
    }
    if (haveValueNotFound) {
      return options.concat({
        value,
        label: 'No encontrado.',
        disabled: true
      });
    }

    return options;
  }, [options, value, haveValueNotFound]);
  return optionsControlled;
};

useSelectOptionsControlled.propTypes = {
  options: PropTypes.arrayOf(Object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default useSelectOptionsControlled;
