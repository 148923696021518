import { isArrayWithItems } from '../../../../utils/functions/validator/arrayValidator';

// eslint-disable-next-line import/prefer-default-export
export const subscriptionExpiredDateFilter = ({ licenses }) => {
  if (!isArrayWithItems(licenses)) {
    return null;
  }
  const [licence] = licenses;
  if (licence?.status !== 'canceled') {
    return null;
  }
  return licence?.endDate;
};
