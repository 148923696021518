const sorterByStringAsc = (first, second) => first?.localeCompare?.(second);

const arraySorterAsc = (first, second) => first - second;

const arraySorterDesc = (first, second) => second - first;

const arraySorterByDateAsc = (first, second, keyToCompare) =>
  arraySorterAsc(first[keyToCompare], second[keyToCompare]);

const arraySorterByDateDesc = (first, second, keyToCompare) =>
  arraySorterDesc(first[keyToCompare], second[keyToCompare]);

const arraySorterByStringAsc = (first, second, keyToCompare) =>
  sorterByStringAsc(first[keyToCompare], second[keyToCompare]);

export {
  arraySorterByStringAsc,
  arraySorterByDateAsc,
  arraySorterByDateDesc,
  arraySorterAsc,
  arraySorterDesc
};
