/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { MAPPINGS_PRODUCTS } from './mappingsProducts.actionTypes';
import mappingsProductsAdapter from './mappingsProducts.entityAdapter';

const initialState = {
  ...mappingsProductsAdapter.getInitialState()
};

const {
  setAll,
  addOne,
  updateOne,
  removeOne,
  removeMany
} = mappingsProductsAdapter;

const mappingsProductsSlice = createSlice({
  name: MAPPINGS_PRODUCTS,
  initialState,
  reducers: {
    SET_ALL: setAll,
    ADD_ONE: addOne,
    UPDATE_ONE: updateOne,
    REMOVE_ONE: removeOne,
    REMOVE_MANY: removeMany,
    SET_PRODUCTS: (state, { payload }) => {
      state.products = payload;
    }
  }
});

export const { actions, reducer } = mappingsProductsSlice;
