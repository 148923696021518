/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NPSelectEdit from './components/NPSelectEdit';
import { InputViewMode } from '../../../components/inputViewMode/InputViewMode';

const NPSelect = props => {
  const { editModeFixed, value, editTrigger, onConfirm, canEdit, deepSearch } =
    props;

  const [inputEditMode, setInputEditMode] = useState(editModeFixed);
  const [originalInputValue, setOriginalInputValue] = useState(value);

  useEffect(() => {
    const setInitialStatus = () => {
      setInputEditMode(editModeFixed);
    };
    setInitialStatus();
  }, [editModeFixed]);

  const onClickEdit = () => {
    setOriginalInputValue(value);
    setInputEditMode(true);
  };

  const onBlurInputEdit = () => {
    const valueIsChanged = originalInputValue !== value;
    const canOnConfirm = onConfirm && valueIsChanged;
    if (canOnConfirm) {
      onConfirm(value);
    }
    const canSetEditModeToFalse =
      (onConfirm && valueIsChanged) || !editModeFixed;
    if (canSetEditModeToFalse) {
      setInputEditMode(false);
    }
  };

  const onKeyPressInputEdit = event => {
    if (event.key === 'Enter') {
      setInputEditMode(false);
    }
  };

  return inputEditMode ? (
    <NPSelectEdit
      onKeyPress={onKeyPressInputEdit}
      onBlur={onBlurInputEdit}
      canEdit={canEdit}
      deepSearch={deepSearch}
      {...props}
    />
  ) : (
    <InputViewMode
      canEdit={canEdit}
      editTrigger={editTrigger}
      onClickEdit={onClickEdit}
      inputType="select"
      {...props}
    />
  );
};

NPSelect.propTypes = {
  editModeFixed: PropTypes.bool,
  showActions: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  editTrigger: PropTypes.arrayOf(String),
  size: PropTypes.string,
  onConfirm: PropTypes.func,
  canEdit: PropTypes.bool,
  deepSearch: PropTypes.bool
};
NPSelect.defaultProps = {
  editModeFixed: false,
  showActions: true,
  value: undefined,
  onChange: undefined,
  onConfirm: undefined,
  editTrigger: undefined,
  size: undefined,
  canEdit: true,
  deepSearch: false
};

export default NPSelect;
