import {
  useSelector as useSelectorByRedux,
  shallowEqual as shallowEqualByRedux
} from 'react-redux';
import { isEqualObject } from '../functions/validator/objectValidator';

const deepShallowEqual = (prevData, data) => isEqualObject(prevData, data);

const shallowEqual = shallowEqualByRedux || deepShallowEqual;

const useSelector = (selector, extraData = {}) =>
  useSelectorByRedux(store => selector(store, extraData), shallowEqual);

export default useSelector;
