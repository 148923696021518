/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import setFiltersBySliceReducer from '../../utils/functions/setFiltersBySliceReducer';
import { ARRIVALS } from './arrivals.actionTypes';
import arrivalsAdapter from './arrivals.entityAdapter';

const initialState = {
  ...arrivalsAdapter.getInitialState(),
  filters: {}
};

const {
  setAll,
  addOne,
  updateOne,
  updateMany,
  upsertMany,
  removeOne,
  removeMany
} = arrivalsAdapter;

const arrivalsSlice = createSlice({
  name: ARRIVALS,
  initialState,
  reducers: {
    SET_ALL: setAll,
    ADD_ONE: addOne,
    UPDATE_ONE: updateOne,
    UPDATE_MANY: updateMany,
    UPSERT_MANY: upsertMany,
    REMOVE_ONE: removeOne,
    REMOVE_MANY: removeMany,
    SET_FILTERS: setFiltersBySliceReducer,
    SET_TOTAL_SCORING: (state, { payload }) => {
      state.totalScoring = payload;
    }
  }
});

export const { actions, reducer } = arrivalsSlice;
