import { createSelector } from '@reduxjs/toolkit';
import selectOptionsFormatter from '../../../utils/functions/formatter/selectOptionsFormatter';
import { isArrayWithItems } from '../../../utils/functions/validator/arrayValidator';
import { subscriptionExpiredDateFilter } from './utils/shared.filters';

export const countriesSelector = ({ shared }) => shared.countries;

export const countriesOptionsSelector = createSelector(
  countriesSelector,
  countries =>
    selectOptionsFormatter({
      options: countries,
      value: 'iso',
      label: 'name'
    })
);

export const isLoadingCountriesSelector = ({ shared }) =>
  shared?.isLoadingCountries;

export const currentUserCompanyIdSelector = ({ shared }) =>
  shared?.currentUser?.companyId;

export const currentUserIsAdminSelector = ({ shared }) =>
  shared?.currentUser?.type === 'admin';

export const licensesActiveSelector = ({ shared }) => shared?.licenses;

export const currentUserLangSelector = ({ shared }) =>
  shared?.currentUser?.lang;

export const badgesSelector = ({ shared }) => shared?.badges;

export const subscriptionExpiredDateSelector = createSelector(
  licensesActiveSelector,
  licenses => subscriptionExpiredDateFilter({ licenses })
);

export const userHasAccessSelector = createSelector(
  licensesActiveSelector,
  licenses => {
    if (!isArrayWithItems(licenses)) {
      return false;
    }

    const [currentLicence] = licenses;
    return currentLicence?.userHasAccess;
  }
);

export const selectPmsIntegrations = ({ shared }) => shared?.pmss;

export const selectPmsIntegrationsOptions = createSelector(
  selectPmsIntegrations,
  pmss =>
    pmss &&
    selectOptionsFormatter({
      options: pmss,
      value: 'id',
      label: 'name'
    })
);
