/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import setFiltersBySliceReducer from '../../utils/functions/setFiltersBySliceReducer';
import { MAPPINGS } from './mappings.actionTypes';

const initialState = {};

const mappingsSeasonsSlice = createSlice({
  name: MAPPINGS,
  initialState,
  reducers: {
    SET_FILTERS: setFiltersBySliceReducer
  }
});

export const { actions, reducer } = mappingsSeasonsSlice;
