import { createEntityAdapter } from '@reduxjs/toolkit';
import {
  arraySorterByDateDesc,
  arraySorterByStringAsc
} from '../../../../utils/functions/arrayFunctions';

const mappingsRoomsAdapter = createEntityAdapter({
  selectId: mappingsRooms => mappingsRooms.mappingRoomId,
  sortComparer: (prev, next) => {
    switch (true) {
      case prev.createdAt === next.createdAt &&
        prev.matchedCode === next.matchedCode:
        return arraySorterByStringAsc(prev, next, 'mappingRoomId');
      case prev.createdAt === next.createdAt:
        return arraySorterByStringAsc(prev, next, 'matchedCode');
      default:
        return arraySorterByDateDesc(prev, next, 'createdAt');
    }
  }
});

export default mappingsRoomsAdapter;
