import { createEntityAdapter } from '@reduxjs/toolkit';
import {
  arraySorterByDateDesc,
  arraySorterByStringAsc
} from '../../../utils/functions/arrayFunctions';

const trackingsAdapter = createEntityAdapter({
  selectId: trackings => trackings.trackingId,
  sortComparer: (prev, next) => {
    switch (true) {
      case prev.createdAt === next.createdAt &&
        prev.inhouseBookingCode === next.inhouseBookingCode:
        return arraySorterByStringAsc(prev, next, 'trackingId');
      case prev.inhouseBookingCode === next.inhouseBookingCode:
        return arraySorterByDateDesc(prev, next, 'createdAt');
      default:
        return arraySorterByStringAsc(prev, next, 'inhouseBookingCode');
    }
  }
});

export default trackingsAdapter;
