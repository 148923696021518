/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { USERS } from './users.actionTypes';
import usersAdapter from './users.entityAdapter';


const initialState = {
  ...usersAdapter.getInitialState()
};

const { setAll, addOne, updateOne, removeOne, removeMany } = usersAdapter;

const usersSlice = createSlice({
  name: USERS,
  initialState,
  reducers: {
    SET_ALL: setAll,
    ADD_ONE: addOne,
    UPDATE_ONE: updateOne,
    REMOVE_ONE: removeOne,
    REMOVE_MANY: removeMany
  }
});

export const { actions, reducer } = usersSlice;
