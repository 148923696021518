import { useRequest as useRequestAhooks } from 'ahooks';

const useRequest = (props = {}) => {
  const {
    service,
    manual,
    initialData,
    refreshDeps,
    onSuccess,
    onError,
    formatResult,
    cacheKey,
    loadingDelay,
    defaultParams,
    pollingInterval,
    pollingWhenHidden,
    fetchKey,
    refreshOnWindowFocus,
    focusTimespan,
    debounceInterval,
    throttleInterval,
    ready
  } = props;
  const {
    data,
    error,
    loading,
    run,
    params,
    cancel,
    refresh,
    mutate,
    fetches
  } = useRequestAhooks(service, {
    manual,
    initialData,
    refreshDeps,
    onSuccess,
    onError,
    formatResult,
    cacheKey,
    loadingDelay,
    defaultParams,
    pollingInterval,
    pollingWhenHidden,
    fetchKey,
    refreshOnWindowFocus,
    focusTimespan,
    debounceInterval,
    throttleInterval,
    ready,
    throwOnError: true
  });

  return {
    data,
    error,
    isLoading: loading,
    run,
    params,
    cancel,
    refresh,
    mutate,
    fetches
  };
};

export default useRequest;
