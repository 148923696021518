import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import onDeepSearch from '../../../../../../../utils/functions/validator/deepSearch';

import { isSourceContainsSearch } from '../../../../../../../utils/functions/formatter/stringFormatter';
import { isObjectWithValues } from '../../../../../../../utils/functions/validator/objectValidator';
import { isArrayWithItems } from '../../../../../../../utils/functions/validator/arrayValidator';

const { Option, OptGroup } = Select;

const NPSelectEdit = props => {
  const {
    name,
    optionLabelProp,
    onChange,
    options,
    placeholder,
    style,
    defaultValue,
    onSearch,
    notFoundContent,
    mode,
    onSelect,
    suffixIcon,
    className,
    showSearch,
    type,
    loading,
    allowClear,
    disabledAll,
    size,
    optionFilterProp,
    onDeselect,
    required,
    dropdownClassName,
    selectId,
    showAllTextInDropdown,
    value,
    onBlur,
    onKeyPress,
    autoFocus,
    deepSearch,
    autoComplete,
    dropdownMatchSelectWidth
  } = props;

  const selectRef = useRef(null);

  useEffect(() => {
    const checkToFocus = () => {
      const canFocus =
        autoFocus && isObjectWithValues(selectRef) && selectRef.current;
      if (canFocus) {
        selectRef.current.focus();
      }
    };

    checkToFocus();
  }, [autoFocus]);

  const handleFilterOption = (inputValue, option) => {
    if (deepSearch) {
      return onDeepSearch(inputValue, option);
    }
    return isSourceContainsSearch(option.labelToSearch, inputValue);
  };

  const valueFormatted = useMemo(() => (value === null ? undefined : value), [
    value
  ]);

  const isValueDisabled = useMemo(() => {
    if (!isArrayWithItems(options)) {
      return '';
    }

    if (!value) {
      return '';
    }

    const optionSelected = options.find(option => option.value === value);

    if (!isObjectWithValues(optionSelected)) {
      return '';
    }

    const { disabled } = optionSelected;
    if (!disabled) {
      return '';
    }
    return 'value-selected-disabled';
  }, [value, options]);

  return (
    <div className={`select-edit-mode  ${size || ''} ${className || ''}`}>
      <Select
        ref={selectRef}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        name={name}
        id={selectId}
        className={`${required ? 'required-input' : ''} ${isValueDisabled}`}
        onChange={onChange}
        value={valueFormatted}
        style={style}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onSearch={onSearch}
        notFoundContent={showSearch ? 'Sin resultados' : notFoundContent}
        mode={mode}
        onSelect={onSelect}
        suffixIcon={suffixIcon}
        showSearch={showSearch}
        optionFilterProp={optionFilterProp}
        loading={loading}
        allowClear={allowClear}
        disabled={disabledAll}
        size={size}
        autoComplete={autoComplete}
        onDeselect={onDeselect}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        dropdownClassName={`${dropdownClassName || ''} ${
          showAllTextInDropdown ? 'word-break' : ''
        }`}
        filterOption={showSearch ? handleFilterOption : false}
        optionLabelProp={optionLabelProp}
      >
        {Array.isArray(options) && type !== 'grouped'
          ? options.map(
              ({
                label,
                value: optionValue,
                children,
                disabled,
                itemClassName,
                selectedComponent
              }) => {
                return (
                  <Option
                    key={optionValue}
                    value={optionValue}
                    label={selectedComponent || label}
                    labelToSearch={label}
                    disabled={disabled}
                    className={itemClassName}
                  >
                    {children || label}
                  </Option>
                );
              }
            )
          : Array.isArray(options) &&
            options.map(({ groupId, groupLabel, groupOptions }) => {
              return (
                <OptGroup key={groupId} label={groupLabel}>
                  {Array.isArray(groupOptions) &&
                    groupOptions.map(
                      ({ label, value: optionValue, children, disabled }) => (
                        <Option
                          key={optionValue}
                          value={optionValue}
                          label={label}
                          labelToSearch={label}
                          disabled={disabled}
                          size={size}
                        >
                          {children || label}
                        </Option>
                      )
                    )}
                </OptGroup>
              );
            })}
      </Select>
    </div>
  );
};

NPSelectEdit.propTypes = {
  name: PropTypes.string,
  optionLabelProp: PropTypes.string,
  onChange: PropTypes.func,
  selectId: PropTypes.string,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(Object),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(Array)
  ]),
  placeholder: PropTypes.string,
  notFoundContent: PropTypes.string,
  mode: PropTypes.string,
  optionFilterProp: PropTypes.string,
  defaultValue: PropTypes.arrayOf(String),
  style: PropTypes.objectOf(Object),
  suffixIcon: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.string,
  showSearch: PropTypes.bool,
  loading: PropTypes.bool,
  allowClear: PropTypes.bool,
  disabledAll: PropTypes.bool,
  type: PropTypes.string,
  onDeselect: PropTypes.func,
  required: PropTypes.bool,
  dropdownClassName: PropTypes.string,
  showAllTextInDropdown: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  autoFocus: PropTypes.bool,
  deepSearch: PropTypes.bool,
  autoComplete: PropTypes.string,
  dropdownMatchSelectWidth: PropTypes.bool
};

NPSelectEdit.defaultProps = {
  name: null,
  optionLabelProp: 'children',
  selectId: undefined,
  optionFilterProp: 'label',
  loading: false,
  onChange: null,
  onSearch: undefined,
  onSelect: null,
  options: null,
  style: { width: '100%' },
  value: undefined,
  placeholder: undefined,
  notFoundContent: 'Sin información',
  mode: 'default',
  suffixIcon: undefined,
  className: undefined,
  showSearch: true,
  defaultValue: undefined,
  allowClear: false,
  disabledAll: false,
  type: undefined,
  size: 'default',
  onDeselect: null,
  required: false,
  dropdownClassName: undefined,
  showAllTextInDropdown: false,
  onBlur: undefined,
  onKeyPress: undefined,
  autoFocus: false,
  deepSearch: true,
  autoComplete: 'none',
  dropdownMatchSelectWidth: false
};

export default NPSelectEdit;
export { NPSelectEdit as Select };
