/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import setFiltersBySliceReducer from '../../utils/functions/setFiltersBySliceReducer';
import { TRACKINGS } from './trackings.actionTypes';
import trackingsAdapter from './trackings.entityAdapter';

const initialState = {
  ...trackingsAdapter.getInitialState()
};

const { setAll, addOne, updateOne, removeOne, removeMany } = trackingsAdapter;

const trackingsSlice = createSlice({
  name: TRACKINGS,
  initialState,
  reducers: {
    SET_ALL: setAll,
    ADD_ONE: addOne,
    UPDATE_ONE: updateOne,
    REMOVE_ONE: removeOne,
    REMOVE_MANY: removeMany,
    SET_FILTERS: setFiltersBySliceReducer
  }
});

export const { actions, reducer } = trackingsSlice;
