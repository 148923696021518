import DeepUpSellError from './DeepUpSellError';

export default class ErrorTechnical extends DeepUpSellError {
  constructor(message) {
    super(message);
    this.name = 'Technical Error';
    this.message = this.name;
    this.value = message;
  }
}
