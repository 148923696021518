import { isArray } from '../../../../../../utils/functions/validator/arrayValidator';
import { isNumber } from '../../../../../../utils/functions/validator/numberValidator';
import { isStringWithCharacters } from '../../../../../../utils/functions/validator/stringValidator';
import DeepUpSellError from '../../../../errors/classes/DeepUpSellError';

const validateIfExistValueInOptions = ({
  options,
  valueToValidate,
  selectId = 'Component',
  selectLocationId = ''
}) => {
  const cannotCheckValue = !isArray(options);
  if (cannotCheckValue) {
    return;
  }
  let existValueInOptions = true;
  const canCheckValue =
    isNumber(valueToValidate) || isStringWithCharacters(valueToValidate);
  if (canCheckValue) {
    const isGroupedOptions = options.some(option =>
      Object.keys(option).some(keysOfOption => keysOfOption === 'groupOptions')
    );
    const checkValueInOptions = ({ value }) => {
      return value === valueToValidate;
    };
    if (isGroupedOptions) {
      existValueInOptions = options.some(({ groupOptions }) =>
        groupOptions.some(checkValueInOptions)
      );
    } else {
      existValueInOptions = options.some(checkValueInOptions);
    }
  }
  const notExistValue = !existValueInOptions;
  if (notExistValue) {
    throw new DeepUpSellError(
      `Error in Select ${selectId} ${selectLocationId} value: ${valueToValidate} & options: ${JSON.stringify(
        options
      )}`
    );
  }
};

export default validateIfExistValueInOptions;
