import { isArrayWithItems } from '../validator/arrayValidator';

const selectOptionsFormatter = ({ options, value, label }) =>
  isArrayWithItems(options)
    ? options.map(option => ({
        value: option[value],
        label: option[label],
        option
      }))
    : [];

export default selectOptionsFormatter;
