import { createEntityAdapter } from '@reduxjs/toolkit';
import {
  arraySorterByDateAsc,
  arraySorterByStringAsc,
  arraySorterDesc
} from '../../../utils/functions/arrayFunctions';

const arrivalsAdapter = createEntityAdapter({
  selectId: arrivals => arrivals.bookingId,
  sortComparer: (prev, next) => {
    switch (true) {
      case prev.score === next.score && prev.createdAt === next.createdAt:
        return arraySorterByStringAsc(prev, next, 'bookingId');
      case prev.score === next.score:
        return arraySorterByDateAsc(prev, next, 'createdAt');
      default:
        return arraySorterDesc(prev.score, next.score);
    }
  }
});

export default arrivalsAdapter;
