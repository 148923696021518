import { combineReducers } from 'redux';
import establishments from './sections/establishments/establishments.reducer';
import users from './sections/users/users.reducer';
import sellers from './sections/sellers/sellers.reducer';
import arrivals from './sections/arrivals/arrivals.reducer';
import trackings from './sections/trackings/trackings.reducer';
import results from './sections/results/results.reducer';
import reporting from './sections/reporting/reporting.reducer';
import mappings from './sections/mappings/mappings.reducer';
import shared from './sections/shared/shared.reducer';

const reducers = combineReducers({
  arrivals,
  establishments,
  sellers,
  mappings,
  trackings,
  reporting,
  results,
  users,
  shared
});

export default reducers;
