/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import mappingsSeasonsAdapter from './mappingsSeasons.entityAdapter';
import { MAPPINGS_SEASONS } from './mappingsSeasons.actionTypes';

const initialState = {
  ...mappingsSeasonsAdapter.getInitialState()
};

const {
  setAll,
  addOne,
  updateOne,
  removeOne,
  removeMany
} = mappingsSeasonsAdapter;

const mappingsSeasonsSlice = createSlice({
  name: MAPPINGS_SEASONS,
  initialState,
  reducers: {
    SET_ALL: setAll,
    ADD_ONE: addOne,
    UPDATE_ONE: updateOne,
    REMOVE_ONE: removeOne,
    REMOVE_MANY: removeMany,
    SET_SEASONS: (state, { payload }) => {
      state.seasons = payload;
    }
  }
});

export const { actions, reducer } = mappingsSeasonsSlice;
