import { createEntityAdapter } from '@reduxjs/toolkit';
import {
  arraySorterByDateDesc,
  arraySorterByStringAsc
} from '../../../../utils/functions/arrayFunctions';

const mappingsProductsAdapter = createEntityAdapter({
  selectId: mappingsProducts => mappingsProducts.mappingProductId,
  sortComparer: (prev, next) => {
    switch (true) {
      case prev.createdAt === next.createdAt && prev.code === next.code:
        return arraySorterByStringAsc(prev, next, 'mappingProductId');
      case prev.createdAt === next.createdAt:
        return arraySorterByStringAsc(prev, next, 'code');
      default:
        return arraySorterByDateDesc(prev, next, 'createdAt');
    }
  }
});

export default mappingsProductsAdapter;
