/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import NPSelect from './components/NPSelect/NPSelect';
import Label from '../label/Label';
import useSelectOptionsControlled from './utils/hooks/useSelectOptionsControlled';
import useTranslation from '../../../../i18n/hooks/useTranslation';
import { isStringWithCharacters } from '../../../../utils/functions/validator/stringValidator';
import { isArrayWithItems } from '../../../../utils/functions/validator/arrayValidator';

const Select = props => {
  const {
    label,
    labelPosition,
    size,
    ellipsis,
    layout,
    showInfo,
    tooltipTitle,
    value,
    options,
    boldLabel,
    labelAlignment,
    selectId,
    selectLocationId,
    placeholder,
    ...selectProps
  } = props;

  const { translation } = useTranslation();

  const labelDisplay = useMemo(
    () => (isStringWithCharacters(label) ? translation(label) : label),
    [label, translation]
  );

  const placeholderDisplay = useMemo(
    () =>
      isStringWithCharacters(placeholder)
        ? translation(placeholder)
        : placeholder,
    [placeholder, translation]
  );

  const optionsControlled = useSelectOptionsControlled({
    options,
    value,
    selectId,
    selectLocationId
  });

  const valueControlled = useMemo(() => {
    if (!isArrayWithItems(optionsControlled)) {
      return undefined;
    }
    return value;
  }, [optionsControlled, value]);

  return label ? (
    <Label
      label={labelDisplay}
      size={size}
      ellipsis={ellipsis}
      labelPosition={labelPosition}
      layout={layout}
      showInfo={showInfo}
      tooltipTitle={tooltipTitle}
      boldLabel={boldLabel}
      labelAlignment={labelAlignment}
    >
      <NPSelect
        {...selectProps}
        size={size}
        value={valueControlled}
        placeholder={placeholderDisplay}
        options={optionsControlled}
      />
    </Label>
  ) : (
    <NPSelect
      {...selectProps}
      size={size}
      placeholder={placeholderDisplay}
      value={valueControlled}
      options={optionsControlled}
    />
  );
};

Select.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]),
  size: PropTypes.string,
  placeholder: PropTypes.string,
  ellipsis: PropTypes.bool,
  labelPosition: PropTypes.string,
  layout: PropTypes.objectOf(Object),
  showInfo: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  labelAlignment: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(Object),
  boldLabel: PropTypes.bool,
  selectId: PropTypes.string,
  selectLocationId: PropTypes.string
};

Select.defaultProps = {
  label: undefined,
  size: undefined,
  placeholder: 'general.placeholder',
  ellipsis: false,
  labelPosition: undefined,
  layout: undefined,
  showInfo: false,
  tooltipTitle: undefined,
  value: undefined,
  options: undefined,
  boldLabel: true,
  labelAlignment: undefined,
  selectId: undefined,
  selectLocationId: undefined
};

export { Select as default };
