import { combineReducers } from 'redux';
import agencies from './agencies/mappingsAgencies.reducer';
import pensions from './pensions/mappingsPensions.reducer';
import products from './products/mappingsProducts.reducer';
import seasons from './seasons/mappingsSeasons.reducer';
import rooms from './rooms/mappingsRooms.reducer';
import { reducer as info } from './mappings.slice';

const mappingsReducers = combineReducers({
  agencies,
  pensions,
  products,
  rooms,
  seasons,
  info
});

export default mappingsReducers;
