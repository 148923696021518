import { useState } from 'react';
import PropTypes from 'prop-types';
import useTranslation from '../../../../i18n/hooks/useTranslation';
import { Info } from '../../assets/icons';
import Modal from '../../modal/Modal';

const HowItWorks = ({ videoUrl }) => {
  const [visible, setVisible] = useState(false);
  const { translation } = useTranslation();

  return (
    <>
      <div
        onClick={() => setVisible(true)}
        role="presentation"
        className="how-it-works-trigger"
      >
        <div className="how-it-works-text">
          {translation('howItWorks.title')}
        </div>
        <Info size="extra-large" />
      </div>
      <Modal
        visible={visible}
        width={640}
        className="how-it-works-modal"
        onCancel={() => setVisible(false)}
        footer={false}
        closable={false}
        destroyOnClose
        centered
      >
        <iframe
          width="640"
          height="360"
          title="How it Works"
          src={videoUrl}
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          oallowfullscreen="true"
          mallowfullscreen="true"
        />
      </Modal>
    </>
  );
};

HowItWorks.propTypes = {
  videoUrl: PropTypes.string
};
HowItWorks.defaultProps = {
  videoUrl: undefined
};

export default HowItWorks;
