/* eslint-disable no-param-reassign */
const { createSlice } = require('@reduxjs/toolkit');
const { RESULTS } = require('./results.actionTypes');
const { default: resultsAdapter } = require('./results.entityAdapter');

const initialState = {
  ...resultsAdapter.getInitialState()
};

const { setAll, addOne, updateOne, removeOne, removeMany } = resultsAdapter;

const resultsSlice = createSlice({
  name: RESULTS,
  initialState,
  reducers: {
    SET_ALL: setAll,
    ADD_ONE: addOne,
    UPDATE_ONE: updateOne,
    REMOVE_ONE: removeOne,
    REMOVE_MANY: removeMany
  }
});

export const { actions, reducer } = resultsSlice;
