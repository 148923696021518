/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ESTABLISHMENTS } from './establishments.actionTypes';
import establishmentsAdapter from './establishments.entityAdapter';

const initialState = {
  ...establishmentsAdapter.getInitialState()
};

const {
  setAll,
  addOne,
  updateOne,
  removeOne,
  removeMany
} = establishmentsAdapter;

const establishmentsSlice = createSlice({
  name: ESTABLISHMENTS,
  initialState,
  reducers: {
    SET_ALL: setAll,
    ADD_ONE: addOne,
    UPDATE_ONE: updateOne,
    REMOVE_ONE: removeOne,
    REMOVE_MANY: removeMany,
    SET_TYPES: (state, { payload }) => {
      state.types = payload;
    }
  }
});

export const { actions, reducer } = establishmentsSlice;
