/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { MAPPINGS_AGENCIES } from './mappingsAgencies.actionTypes';
import mappingsAgenciesAdapter from './mappingsAgencies.entityAdapter';

const initialState = {
  ...mappingsAgenciesAdapter.getInitialState()
};

const {
  setAll,
  addOne,
  updateOne,
  removeOne,
  removeMany
} = mappingsAgenciesAdapter;

const mappingsAgenciesSlice = createSlice({
  name: MAPPINGS_AGENCIES,
  initialState,
  reducers: {
    SET_ALL: setAll,
    ADD_ONE: addOne,
    UPDATE_ONE: updateOne,
    REMOVE_ONE: removeOne,
    REMOVE_MANY: removeMany,
    SET_AGENCIES: (state, { payload }) => {
      state.agencies = payload;
    }
  }
});

export const { actions, reducer } = mappingsAgenciesSlice;
