/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { MAPPINGS_ROOMS } from './mappingsRooms.actionTypes';
import mappingsRoomsAdapter from './mappingsRooms.entityAdapter';

const initialState = {
  ...mappingsRoomsAdapter.getInitialState()
};

const {
  setAll,
  addOne,
  updateOne,
  removeOne,
  removeMany
} = mappingsRoomsAdapter;

const mappingsRoomsSlice = createSlice({
  name: MAPPINGS_ROOMS,
  initialState,
  reducers: {
    SET_ALL: setAll,
    ADD_ONE: addOne,
    UPDATE_ONE: updateOne,
    REMOVE_ONE: removeOne,
    REMOVE_MANY: removeMany,
    SET_ROOMS: (state, { payload }) => {
      state.rooms = payload;
    }
  }
});

export const { actions, reducer } = mappingsRoomsSlice;
