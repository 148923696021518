/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import setFiltersBySliceReducer from '../../utils/functions/setFiltersBySliceReducer';
import { REPORTING } from './reporting.actionTypes';
import reportingAdapter from './reporting.entityAdapter';

const initialState = {
  ...reportingAdapter.getInitialState(),
  reportingData: {}
};

const { setAll, addOne, updateOne, removeOne, removeMany } = reportingAdapter;

const reportingSlice = createSlice({
  name: REPORTING,
  initialState,
  reducers: {
    SET_ALL: setAll,
    ADD_ONE: addOne,
    UPDATE_ONE: updateOne,
    REMOVE_ONE: removeOne,
    REMOVE_MANY: removeMany,
    SET_REPORTING_DATA: (state, { payload }) => {
      state.reportingData = payload;
    },
    SET_FILTERS: setFiltersBySliceReducer
  }
});

export const { actions, reducer } = reportingSlice;
