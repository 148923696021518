import store from '../../store';
import {
  countriesSetAction,
  setCurrentUserAction,
  setLicensesAction,
  setBadgesAction,
  isLoadingCountriesAction,
  setSubscriptionExpiredDateAction,
  setPmsIntegrationsAction
} from './shared.actions';

export const countriesSetActionCreator = payload =>
  store.dispatch(countriesSetAction(payload));

export const isLoadingCountriesActionCreator = payload =>
  store.dispatch(isLoadingCountriesAction(payload));

export const setCurrentUserActionCreator = payload =>
  store.dispatch(setCurrentUserAction(payload));

export const setLicensesActionCreator = payload =>
  store.dispatch(setLicensesAction(payload));

export const setBadgesActionCreator = payload =>
  store.dispatch(setBadgesAction(payload));

export const setSubscriptionExpiredDateActionCreator = payload =>
  store.dispatch(setSubscriptionExpiredDateAction(payload));

export const setPmsIntegrationsActionCreator = payload =>
  store.dispatch(setPmsIntegrationsAction(payload));
