import PropTypes from 'prop-types';
import { useMemo } from 'react';
import Card from '../../../card/Card';
import Head from '../../../head/Head';
import HowItWorks from '../../../info/howItWorks/HowItWorks';

const PageContent = ({
  children,
  title,
  headDescription,
  headTitle,
  extra,
  videoUrl,
  className,
  type,
  showHeadBorderBottom,
  cardBodyStyle,
  cardClassName,
  bordered,
  cardStyle
}) => {
  const isSection = useMemo(() => type === 'section', [type]);
  return (
    <div className={`page-content ${className} ${type}`}>
      <Head title={headTitle} description={headDescription} />
      <Card
        className={`${type} ${cardClassName}`}
        title={title}
        extra={videoUrl ? <HowItWorks videoUrl={videoUrl} /> : extra}
        bordered={bordered || !isSection}
        style={cardStyle}
        showHeadBorderBottom={showHeadBorderBottom}
        bodyStyle={cardBodyStyle}
      >
        {children}
      </Card>
    </div>
  );
};

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]).isRequired,
  headTitle: PropTypes.string,
  headDescription: PropTypes.string,
  videoUrl: PropTypes.string,
  extra: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]),
  cardClassName: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  showHeadBorderBottom: PropTypes.bool,
  cardBodyStyle: PropTypes.objectOf(Object),
  bordered: PropTypes.bool,
  cardStyle: PropTypes.objectOf(Object)
};
PageContent.defaultProps = {
  headTitle: undefined,
  headDescription: undefined,
  videoUrl: undefined,
  extra: undefined,
  className: '',
  cardClassName: '',
  type: 'default',
  showHeadBorderBottom: false,
  cardBodyStyle: undefined,
  bordered: false,
  cardStyle: { padding: '1rem' }
};

export default PageContent;
