import DeepUpSellError from './DeepUpSellError';

export default class Error401 extends DeepUpSellError {
  constructor(message) {
    super(message);
    this.name = 'Error 401';
    this.message = this.name;
    this.value = message;
  }
}
