import { actions } from './shared.slice';

export const {
  SET_COUNTRIES: countriesSetAction,
  SET_IS_LOADING_COUNTRIES: isLoadingCountriesAction,
  SET_CURRENT_USER: setCurrentUserAction,
  SET_LICENCES: setLicensesAction,
  SET_BADGES: setBadgesAction,
  SET_SUBSCRIPTION_EXPIRED_DATE: setSubscriptionExpiredDateAction,
  SET_PMS_INTEGRATIONS: setPmsIntegrationsAction
} = actions;
