import { useUpdateEffect } from 'ahooks';
import { useMemo } from 'react';
import Fetch from '../../components/shared/api/Fetch';
import { setBadgesActionCreator } from '../../redux/sections/shared/shared.actionCreators';
import { badgesSelector } from '../../redux/sections/shared/shared.selectors';
import useRequest from './useRequest';
import useSelector from './useSelector';

const readNotificationsApiParams = {
  method: 'GET',
  endpoint: 'notifications'
};

const useBadges = (props = {}) => {
  const { readManual = true } = props;
  const badges = useSelector(badgesSelector);
  const { data, run: readBadges } = useRequest({
    service: () => {
      return Fetch({
        ...readNotificationsApiParams
      });
    },
    manual: readManual
  });
  useUpdateEffect(() => {
    setBadgesActionCreator(data);
  }, [data]);

  const objectBadges = useMemo(() => ({ badges, readBadges }), [
    badges,
    readBadges
  ]);
  return objectBadges;
};

export default useBadges;
