import PropTypes from 'prop-types';
import { Modal as ModalAntd } from 'antd';
import useTranslation from '../../../i18n/hooks/useTranslation';

const Modal = ({
  children,
  visible,
  onCancel,
  onConfirm,
  className,
  footer,
  onConfirmType,
  title,
  width,
  maskClosable,
  confirmLoading,
  cancelText,
  onConfirmText,
  closable,
  destroyOnClose,
  centered,
  onConfirmBtnProps,
  onCancelBtnProps
}) => {
  const { translation } = useTranslation();

  return (
    <ModalAntd
      wrapClassName={`modal ${className}`}
      visible={visible}
      onCancel={onCancel}
      onOk={onConfirm}
      footer={footer}
      okType={onConfirmType}
      title={title}
      width={width}
      maskClosable={maskClosable}
      confirmLoading={confirmLoading}
      cancelText={translation(cancelText)}
      okText={translation(onConfirmText)}
      closable={closable}
      destroyOnClose={destroyOnClose}
      centered={centered}
      okButtonProps={onConfirmBtnProps}
      cancelButtonProps={onCancelBtnProps}
    >
      {children}
    </ModalAntd>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]).isRequired,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  className: PropTypes.string,
  footer: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  onConfirmType: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maskClosable: PropTypes.bool,
  confirmLoading: PropTypes.bool,
  cancelText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]),
  closable: PropTypes.bool,
  onConfirmText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]),
  destroyOnClose: PropTypes.bool,
  centered: PropTypes.bool,
  onConfirmBtnProps: PropTypes.objectOf(Object),
  onCancelBtnProps: PropTypes.objectOf(Object)
};
Modal.defaultProps = {
  visible: false,
  onCancel: undefined,
  onConfirm: undefined,
  className: '',
  footer: undefined,
  onConfirmType: 'primary',
  title: undefined,
  width: 520,
  maskClosable: true,
  confirmLoading: false,
  cancelText: 'general.cancel',
  closable: true,
  onConfirmText: 'general.confirm',
  destroyOnClose: false,
  centered: false,
  onConfirmBtnProps: undefined,
  onCancelBtnProps: undefined
};

export default Modal;
