/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { MAPPINGS_PENSIONS } from './mappingsPensions.actionTypes';
import mappingsPensionsAdapter from './mappingsPensions.entityAdapter';

const initialState = {
  ...mappingsPensionsAdapter.getInitialState()
};

const {
  setAll,
  addOne,
  updateOne,
  removeOne,
  removeMany
} = mappingsPensionsAdapter;

const mappingsPensionsSlice = createSlice({
  name: MAPPINGS_PENSIONS,
  initialState,
  reducers: {
    SET_ALL: setAll,
    ADD_ONE: addOne,
    UPDATE_ONE: updateOne,
    REMOVE_ONE: removeOne,
    REMOVE_MANY: removeMany,
    SET_PENSIONS: (state, { payload }) => {
      state.pensions = payload;
    }
  }
});

export const { actions, reducer } = mappingsPensionsSlice;
