/* eslint-disable no-param-reassign */

import { isArrayWithItems } from '../../../utils/functions/validator/arrayValidator';
import { isObjectWithValues } from '../../../utils/functions/validator/objectValidator';

const setFiltersBySliceReducer = (state, { payload }) => {
  if (!state.filters) {
    state.filters = {};
  }
  if (isObjectWithValues(payload)) {
    Object.entries(payload).forEach(([filterKey, filterValue]) => {
      state.filters[filterKey] = filterValue;
    });
  } else if (isArrayWithItems(payload)) {
    payload.forEach(({ name, value }) => {
      state.filters[name] = value;
    });
  }
  return state;
};

export default setFiltersBySliceReducer;
