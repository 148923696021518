import * as Sentry from '@sentry/react';
import * as endpoints from './endpoints';
import errorNotification from '../notifications/errorNotification';
import DeepUpSellError from '../errors/classes/DeepUpSellError';
import Error400 from '../errors/classes/Error400';
import Error401 from '../errors/classes/Error401';
import Error404 from '../errors/classes/Error404';
import Error409 from '../errors/classes/Error409';
import ErrorTechnical from '../errors/classes/ErrorTechnical';

const getRealEndpoint = (endpoint, params) => {
  let realEndpoint = endpoints[endpoint];
  if (Array.isArray(params)) {
    params.forEach(({ name, value }) => {
      realEndpoint = realEndpoint.replace(name, value);
    });
  }
  return realEndpoint;
};

const controlErrorsWorker = async (
  { response, body, url },
  eventsOnError = {}
) => {
  const { status } = response;

  if (typeof status !== 'number') return false;
  const isCustomError = Object.keys(eventsOnError).some(
    customCode => parseInt(customCode, 10) === status
  );

  const responseData = await response.json();

  if (isCustomError) {
    return eventsOnError[status](response, {
      response: responseData
    });
  }
  const infoToSentry = { response, responseData, body, url };
  const { message } = responseData;
  switch (true) {
    case status === 400:
      errorNotification(message);
      throw new Error400(infoToSentry);
    case status === 401:
      errorNotification(message);
      throw new Error401(infoToSentry);
    case status === 404:
      errorNotification(message);
      throw new Error404(infoToSentry);
    case status === 409:
      errorNotification(message);
      throw new Error409(infoToSentry);
    case status >= 500 && status < 600:
      if (eventsOnError.errorTechnical) return eventsOnError.errorTechnical();
      errorNotification('An unexpected error has occurred');
      throw new ErrorTechnical(infoToSentry);
    default:
      throw new DeepUpSellError(infoToSentry);
  }
};

async function Fetch(config, eventsOnError) {
  try {
    const {
      endpoint,
      method,
      urlParams,
      body,
      params,
      endpointFromBack,
      signal,
      headers
    } = config;
    const realEndpoint = getRealEndpoint(endpoint, params);
    const url = `${endpointFromBack ? '' : endpoints.host}${
      endpointFromBack || realEndpoint
    }${urlParams || ''}`;
    const configConnection = {
      method,
      headers: endpoints[headers || 'headers'],
      signal
    };

    if (body) {
      configConnection.body = JSON.stringify(body);
    }

    const response = await fetch(url, configConnection);
    const { status } = response;
    switch (true) {
      case status === 403: {
        window.location.replace('/login');
        return false;
      }
      case status >= 200 && status <= 299: {
        const responseJson = await response.json();
        return responseJson;
      }
      case status >= 400 && status < 600: {
        await controlErrorsWorker({ response, body, url }, eventsOnError);
        break;
      }
      default:
        return Promise.resolve();
    }
  } catch (error) {
    Sentry.captureException(error, { extra: error.value });
    return Promise.resolve();
  }
}

export default Fetch;
