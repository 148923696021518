import { isRegexMatch } from './regularExpressions';

const onDeepSearch = (inputValue, option) => {
  const inputWords = inputValue.split(' ');
  const doAllWordsMatchOption = inputWords.every(word => {
    const wordWithScapedSpecialChars = word.replace(
      /[-/\\^$*+?.()|[\]{}]/g,
      '\\$&'
    );
    const wordRegex = new RegExp(wordWithScapedSpecialChars, 'gi');
    const doesWordMatchOption = isRegexMatch(wordRegex, option.label);
    return doesWordMatchOption;
  });
  return doAllWordsMatchOption;
};

export default onDeepSearch;
