/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { SELLERS } from './sellers.actionTypes';
import sellersAdapter from './sellers.entityAdapter';

const initialState = {
  ...sellersAdapter.getInitialState()
};

const { setAll, addOne, updateOne, removeOne, removeMany } = sellersAdapter;

const sellersSlice = createSlice({
  name: SELLERS,
  initialState,
  reducers: {
    SET_ALL: setAll,
    ADD_ONE: addOne,
    UPDATE_ONE: updateOne,
    REMOVE_ONE: removeOne,
    REMOVE_MANY: removeMany
  }
});

export const { actions, reducer } = sellersSlice;
